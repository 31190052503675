export const movieTags = [
  {
    value: '经典',
    label: '经典',
  },
  {
    value: '励志',
    label: '励志',
  },
  {
    value: '信念',
    label: '信念',
  },
  {
    value: '自由',
    label: '自由',
  },
  {
    value: '人性',
    label: '人性',
  },
  {
    value: '人生',
    label: '人生',
  },
  {
    value: '美国',
    label: '美国',
  },
  {
    value: '剧情',
    label: '剧情',
  },
  {
    value: '二战',
    label: '二战',
  },
  {
    value: '斯皮尔伯格',
    label: '斯皮尔伯格',
  },
  {
    value: '战争',
    label: '战争',
  },
  {
    value: '辛德勒的名单',
    label: '辛德勒的名单',
  },
  {
    value: '魔幻',
    label: '魔幻',
  },
  {
    value: '史诗',
    label: '史诗',
  },
  {
    value: '奥斯卡',
    label: '奥斯卡',
  },
  {
    value: '新西兰',
    label: '新西兰',
  },
  {
    value: '2003',
    label: '2003',
  },
  {
    value: '黑色',
    label: '黑色',
  },
  {
    value: '黑色幽默',
    label: '黑色幽默',
  },
  {
    value: '黑帮',
    label: '黑帮',
  },
  {
    value: '犯罪',
    label: '犯罪',
  },
  {
    value: '1994',
    label: '1994',
  },
  {
    value: '大片',
    label: '大片',
  },
  {
    value: '2001',
    label: '2001',
  },
  {
    value: '心理',
    label: '心理',
  },
  {
    value: '暴力',
    label: '暴力',
  },
  {
    value: '悬疑',
    label: '悬疑',
  },
  {
    value: '科幻',
    label: '科幻',
  },
  {
    value: '星球大战',
    label: '星球大战',
  },
  {
    value: '1980',
    label: '1980',
  },
  {
    value: '动作',
    label: '动作',
  },
  {
    value: '冒险',
    label: '冒险',
  },
  {
    value: '黑客帝国',
    label: '黑客帝国',
  },
  {
    value: '哲学',
    label: '哲学',
  },
  {
    value: '1999',
    label: '1999',
  },
  {
    value: '1990',
    label: '1990',
  },
  {
    value: '传记',
    label: '传记',
  },
  {
    value: '黑泽明',
    label: '黑泽明',
  },
  {
    value: '日本',
    label: '日本',
  },
  {
    value: '日本电影',
    label: '日本电影',
  },
  {
    value: '七武士',
    label: '七武士',
  },
  {
    value: '武士',
    label: '武士',
  },
  {
    value: '惊悚',
    label: '惊悚',
  },
  {
    value: '沉默的羔羊',
    label: '沉默的羔羊',
  },
  {
    value: '美国电影',
    label: '美国电影',
  },
  {
    value: '汤姆·汉克斯',
    label: '汤姆·汉克斯',
  },
  {
    value: '监狱',
    label: '监狱',
  },
  {
    value: '奇幻',
    label: '奇幻',
  },
  {
    value: '1977',
    label: '1977',
  },
  {
    value: '蜘蛛侠',
    label: '蜘蛛侠',
  },
  {
    value: '动画',
    label: '动画',
  },
  {
    value: '漫威',
    label: '漫威',
  },
  {
    value: 'Marvel',
    label: 'Marvel',
  },
  {
    value: '索尼',
    label: '索尼',
  },
  {
    value: '电影',
    label: '电影',
  },
  {
    value: '2020',
    label: '2020',
  },
  {
    value: '希区柯克',
    label: '希区柯克',
  },
  {
    value: '1960',
    label: '1960',
  },
  {
    value: 'AlfredHitchcock',
    label: 'AlfredHitchcock',
  },
  {
    value: '角斗士',
    label: '角斗士',
  },
  {
    value: '罗马',
    label: '罗马',
  },
  {
    value: '历史',
    label: '历史',
  },
  {
    value: 'EdwardNorton',
    label: 'EdwardNorton',
  },
  {
    value: '种族歧视',
    label: '种族歧视',
  },
  {
    value: '1998',
    label: '1998',
  },
  {
    value: '1995',
    label: '1995',
  },
  {
    value: '推理',
    label: '推理',
  },
  {
    value: '温情',
    label: '温情',
  },
  {
    value: '法国',
    label: '法国',
  },
  {
    value: '感人',
    label: '感人',
  },
  {
    value: '喜剧',
    label: '喜剧',
  },
  {
    value: '法式情怀',
    label: '法式情怀',
  },
  {
    value: '友情',
    label: '友情',
  },
  {
    value: '搞笑',
    label: '搞笑',
  },
  {
    value: '西部',
    label: '西部',
  },
  {
    value: '往事三部曲',
    label: '往事三部曲',
  },
  {
    value: '意大利',
    label: '意大利',
  },
  {
    value: '1968',
    label: '1968',
  },
  {
    value: '西部片',
    label: '西部片',
  },
  {
    value: '失忆',
    label: '失忆',
  },
  {
    value: '记忆',
    label: '记忆',
  },
  {
    value: '探险',
    label: '探险',
  },
  {
    value: '1981',
    label: '1981',
  },
  {
    value: '黑白',
    label: '黑白',
  },
  {
    value: '黑色电影',
    label: '黑色电影',
  },
  {
    value: '1950',
    label: '1950',
  },
  {
    value: '比利怀尔德',
    label: '比利怀尔德',
  },
  {
    value: '恐怖',
    label: '恐怖',
  },
  {
    value: '库布里克',
    label: '库布里克',
  },
  {
    value: '斯坦利·库布里克',
    label: '斯坦利·库布里克',
  },
  {
    value: '恐怖片',
    label: '恐怖片',
  },
  {
    value: '伦理',
    label: '伦理',
  },
  {
    value: '家庭',
    label: '家庭',
  },
  {
    value: '韩国',
    label: '韩国',
  },
  {
    value: '复仇',
    label: '复仇',
  },
  {
    value: '朴赞郁',
    label: '朴赞郁',
  },
  {
    value: '韩国电影',
    label: '韩国电影',
  },
  {
    value: '崔岷植',
    label: '崔岷植',
  },
  {
    value: '莫扎特',
    label: '莫扎特',
  },
  {
    value: '音乐',
    label: '音乐',
  },
  {
    value: '人物',
    label: '人物',
  },
  {
    value: '迪斯尼',
    label: '迪斯尼',
  },
  {
    value: 'pixar',
    label: 'pixar',
  },
  {
    value: '玩具总动员',
    label: '玩具总动员',
  },
  {
    value: '动画片',
    label: '动画片',
  },
  {
    value: 'DISNEY',
    label: 'DISNEY',
  },
  {
    value: '勇敢的心',
    label: '勇敢的心',
  },
  {
    value: '梅尔·吉布森',
    label: '梅尔·吉布森',
  },
  {
    value: '小丑',
    label: '小丑',
  },
  {
    value: 'DC',
    label: 'DC',
  },
  {
    value: '杰昆菲尼克斯',
    label: '杰昆菲尼克斯',
  },
  {
    value: '蝙蝠侠',
    label: '蝙蝠侠',
  },
  {
    value: '2019',
    label: '2019',
  },
  {
    value: '超级英雄',
    label: '超级英雄',
  },
  {
    value: '宫崎骏',
    label: '宫崎骏',
  },
  {
    value: '幽灵公主',
    label: '幽灵公主',
  },
  {
    value: '日本动画',
    label: '日本动画',
  },
  {
    value: '动漫',
    label: '动漫',
  },
  {
    value: '宮崎駿',
    label: '宮崎駿',
  },
  {
    value: '成长',
    label: '成长',
  },
  {
    value: '青春',
    label: '青春',
  },
  {
    value: '爱情',
    label: '爱情',
  },
  {
    value: '二次元',
    label: '二次元',
  },
  {
    value: '感动',
    label: '感动',
  },
  {
    value: '治愈',
    label: '治愈',
  },
  {
    value: '美国往事',
    label: '美国往事',
  },
  {
    value: '罗伯特·德尼罗',
    label: '罗伯特·德尼罗',
  },
  {
    value: '儿童',
    label: '儿童',
  },
  {
    value: '社会',
    label: '社会',
  },
  {
    value: '难民',
    label: '难民',
  },
  {
    value: '黎巴嫩',
    label: '黎巴嫩',
  },
  {
    value: 'Pixar',
    label: 'Pixar',
  },
  {
    value: '3D',
    label: '3D',
  },
  {
    value: '1983',
    label: '1983',
  },
  {
    value: '文艺',
    label: '文艺',
  },
  {
    value: '2004',
    label: '2004',
  },
  {
    value: '太空',
    label: '太空',
  },
  {
    value: '加拿大',
    label: '加拿大',
  },
  {
    value: '宗教',
    label: '宗教',
  },
  {
    value: '2010',
    label: '2010',
  },
  {
    value: '加拿大电影',
    label: '加拿大电影',
  },
  {
    value: 'DenisVilleneuve',
    label: 'DenisVilleneuve',
  },
  {
    value: '发条橙',
    label: '发条橙',
  },
  {
    value: '英国',
    label: '英国',
  },
  {
    value: 'StanleyKubrick',
    label: 'StanleyKubrick',
  },
  {
    value: '1973',
    label: '1973',
  },
  {
    value: 'PaulNewman',
    label: 'PaulNewman',
  },
  {
    value: '布鲁斯·威利斯',
    label: '布鲁斯·威利斯',
  },
  {
    value: 'BruceWillis',
    label: 'BruceWillis',
  },
  {
    value: '虎胆龙威',
    label: '虎胆龙威',
  },
  {
    value: '德国',
    label: '德国',
  },
  {
    value: '纳粹',
    label: '纳粹',
  },
  {
    value: '欧洲',
    label: '欧洲',
  },
  {
    value: '漫画改编',
    label: '漫画改编',
  },
  {
    value: '2005',
    label: '2005',
  },
  {
    value: '莱昂纳多·迪卡普里奥',
    label: '莱昂纳多·迪卡普里奥',
  },
  {
    value: '商业',
    label: '商业',
  },
  {
    value: '马丁·斯科塞斯',
    label: '马丁·斯科塞斯',
  },
  {
    value: 'LeonardoDiCaprio',
    label: 'LeonardoDiCaprio',
  },
  {
    value: '黑人平权',
    label: '黑人平权',
  },
  {
    value: '种族',
    label: '种族',
  },
  {
    value: '真实事件改编',
    label: '真实事件改编',
  },
  {
    value: '公路',
    label: '公路',
  },
  {
    value: 'MartinScorsese',
    label: 'MartinScorsese',
  },
  {
    value: 'RobertDeNiro',
    label: 'RobertDeNiro',
  },
  {
    value: '恐龙',
    label: '恐龙',
  },
  {
    value: '侏罗纪公园',
    label: '侏罗纪公园',
  },
  {
    value: '1993',
    label: '1993',
  },
  {
    value: '西班牙',
    label: '西班牙',
  },
  {
    value: '童话',
    label: '童话',
  },
  {
    value: '潘神的迷宫',
    label: '潘神的迷宫',
  },
  {
    value: '2006',
    label: '2006',
  },
  {
    value: '灵异',
    label: '灵异',
  },
  {
    value: '牛仔',
    label: '牛仔',
  },
  {
    value: '1948',
    label: '1948',
  },
  {
    value: '约翰·休斯顿',
    label: '约翰·休斯顿',
  },
  {
    value: 'JohnHuston',
    label: 'JohnHuston',
  },
  {
    value: '亲情',
    label: '亲情',
  },
  {
    value: '大卫·林奇',
    label: '大卫·林奇',
  },
  {
    value: 'DavidLynch',
    label: 'DavidLynch',
  },
  {
    value: '象人',
    label: '象人',
  },
  {
    value: '波兰斯基',
    label: '波兰斯基',
  },
  {
    value: 'RomanPolanski',
    label: 'RomanPolanski',
  },
  {
    value: '罗曼·波兰斯基',
    label: '罗曼·波兰斯基',
  },
  {
    value: '1954',
    label: '1954',
  },
  {
    value: '政治',
    label: '政治',
  },
  {
    value: 'NataliePortman',
    label: 'NataliePortman',
  },
  {
    value: '科恩兄弟',
    label: '科恩兄弟',
  },
  {
    value: '1996',
    label: '1996',
  },
  {
    value: 'TomHanks',
    label: 'TomHanks',
  },
  {
    value: 'ClintEastwood',
    label: 'ClintEastwood',
  },
  {
    value: '克林特·伊斯特伍德',
    label: '克林特·伊斯特伍德',
  },
  {
    value: '老爷车',
    label: '老爷车',
  },
  {
    value: '2008',
    label: '2008',
  },
  {
    value: '克林特伊斯特伍德',
    label: '克林特伊斯特伍德',
  },
  {
    value: '1982',
    label: '1982',
  },
  {
    value: '反乌托邦',
    label: '反乌托邦',
  },
  {
    value: '2013',
    label: '2013',
  },
  {
    value: '1959',
    label: '1959',
  },
  {
    value: '1975',
    label: '1975',
  },
  {
    value: '信仰',
    label: '信仰',
  },
  {
    value: '军事',
    label: '军事',
  },
  {
    value: '奉俊昊',
    label: '奉俊昊',
  },
  {
    value: '杀人回忆',
    label: '杀人回忆',
  },
  {
    value: '宋康昊',
    label: '宋康昊',
  },
  {
    value: '2015',
    label: '2015',
  },
  {
    value: '澳大利亚',
    label: '澳大利亚',
  },
  {
    value: '动物',
    label: '动物',
  },
  {
    value: '温暖',
    label: '温暖',
  },
  {
    value: '暗恋',
    label: '暗恋',
  },
  {
    value: '丘吉尔',
    label: '丘吉尔',
  },
  {
    value: '易烊千玺',
    label: '易烊千玺',
  },
  {
    value: '校园暴力',
    label: '校园暴力',
  },
  {
    value: '期待',
    label: '期待',
  },
  {
    value: '周冬雨',
    label: '周冬雨',
  },
  {
    value: '中国电影',
    label: '中国电影',
  },
  {
    value: '国产',
    label: '国产',
  },
  {
    value: '曾國祥',
    label: '曾國祥',
  },
  {
    value: '同性',
    label: '同性',
  },
  {
    value: '同志',
    label: '同志',
  },
  {
    value: 'LGBT',
    label: 'LGBT',
  },
  {
    value: '2017',
    label: '2017',
  },
  {
    value: '赛博朋克',
    label: '赛博朋克',
  },
  {
    value: '旅行',
    label: '旅行',
  },
  {
    value: '时尚',
    label: '时尚',
  },
  {
    value: 'AnneHathaway',
    label: 'AnneHathaway',
  },
  {
    value: '安妮海瑟薇',
    label: '安妮海瑟薇',
  },
  {
    value: '女性',
    label: '女性',
  },
  {
    value: '中国大陆',
    label: '中国大陆',
  },
  {
    value: '北野武',
    label: '北野武',
  },
  {
    value: '菊次郎的夏天',
    label: '菊次郎的夏天',
  },
  {
    value: '童年',
    label: '童年',
  },
  {
    value: '李安',
    label: '李安',
  },
  {
    value: '台湾',
    label: '台湾',
  },
  {
    value: '饮食男女',
    label: '饮食男女',
  },
  {
    value: '台湾电影',
    label: '台湾电影',
  },
  {
    value: '香港',
    label: '香港',
  },
  {
    value: '武侠',
    label: '武侠',
  },
  {
    value: '叶问',
    label: '叶问',
  },
  {
    value: '芭蕾',
    label: '芭蕾',
  },
  {
    value: '水墨',
    label: '水墨',
  },
  {
    value: '视觉艺术',
    label: '视觉艺术',
  },
  {
    value: '古装',
    label: '古装',
  },
  {
    value: '2018',
    label: '2018',
  },
  {
    value: '少年',
    label: '少年',
  },
  {
    value: '梦境',
    label: '梦境',
  },
  {
    value: '香水',
    label: '香水',
  },
  {
    value: '童话改编',
    label: '童话改编',
  },
  {
    value: 'Batman',
    label: 'Batman',
  },
  {
    value: '本·阿弗莱克',
    label: '本·阿弗莱克',
  },
  {
    value: '今敏',
    label: '今敏',
  },
  {
    value: '千年女优',
    label: '千年女优',
  },
  {
    value: '1976',
    label: '1976',
  },
  {
    value: '简·奥斯汀',
    label: '简·奥斯汀',
  },
  {
    value: '英国电影',
    label: '英国电影',
  },
  {
    value: '艺术',
    label: '艺术',
  },
  {
    value: '爱尔兰',
    label: '爱尔兰',
  },
  {
    value: '长镜头',
    label: '长镜头',
  },
  {
    value: '黑色喜剧',
    label: '黑色喜剧',
  },
  {
    value: '2014',
    label: '2014',
  },
]
