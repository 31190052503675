import { baseUrl } from '@/config'
import axios from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  mockDatasets,
  mockMakeInitialRequestForNextQuestion,
  mockMakeSubmitAndRequestForNextQuestion,
  mockSmallSadDataset,
} from './mock'
import {
  Button,
  Input,
  Modal,
  Radio,
  Select,
  Slider,
  Steps,
  Typography,
  message,
} from 'antd'
import { find, isNil } from 'lodash'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player'
import ReactMarkdown from 'react-markdown'
import {
  AudioOutlined,
  BookOutlined,
  DatabaseOutlined,
  UserOutlined,
} from '@ant-design/icons'
import styles from './Mark.scss'
import { useStyles } from '@/hooks/styles'

const isMocking = false // TODO: move to env

// TODO: put it into utils
type OssAssetFolder =
  | 'covers'
  | 'videos'
  | 'audios'
  | 'videos-original'
  | 'audios-original'
// const getIndexString = (index: number) => index.toString().padStart(3, '0')
const getOSSURL = (folder: OssAssetFolder, ossID: string) => {
  // example: https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com/dataset/covers/0001_004.jpeg
  const baseURL = 'https://music-video-inlab.oss-cn-hangzhou.aliyuncs.com'
  const getOSSPath = () => {
    let postfix = '.unknown'
    if (folder === 'covers') {
      postfix = '.jpeg'
    } else if (folder === 'audios') {
      postfix = '.mp3'
    } else if (folder === 'videos') {
      postfix = '.mp4'
    } else if (folder === 'videos-original') {
      postfix = '.mp4'
    } else if (folder === 'audios-original') {
      postfix = '.mp3'
    }
    return `${baseURL}/dataset/${folder}/${ossID}${postfix}`
  }
  return getOSSPath()
}

const MusicOrVideo = (props: {
  width: number
  height: number
  modality: OssAssetFolder
  url: string
  muted: boolean
  controls: boolean
  useIcon?: boolean
}) => {
  const {
    width,
    height,
    modality,
    url,
    muted,
    controls,
    useIcon = false,
  } = props
  if (useIcon) {
    return (
      <div style={{ transform: 'translateY(30px)' }}>
        <AudioOutlined rev={undefined} />
      </div>
    )
  }
  if (modality.startsWith('videos')) {
    return (
      <ReactPlayer
        width={width}
        height={height}
        muted={muted}
        controls={controls}
        url={url}
      />
    )
  } else {
    return (
      <div style={{ width, height }}>
        <ReactAudioPlayer muted={muted} controls={controls} src={url} />
      </div>
    )
  }
}

const AuthPage = (props: {
  setToken: Dispatch<SetStateAction<any>>
  nextStep: () => void
}) => {
  const styleClass = useStyles(styles)
  const { setToken, nextStep } = props

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [captcha, setCaptcha] = useState({ captchaId: '', data: '<svg />' })

  const refreshCaptcha = async () => {
    await axios
      .get(`${baseUrl}/admin/base/open/captcha?height=32&width=110`)
      .then((res) => {
        setCaptcha(res.data.data)
        return
      })
      .catch((error) => {
        console.log('error', error)
        return
      })
  }

  const login = async () => {
    const res = await axios.post(`${baseUrl}/admin/base/open/login`, {
      username,
      password,
      captchaId: captcha.captchaId,
      verifyCode,
    })
    console.log(res)
    if (res.data.code === 1001) {
      message.error(res.data?.message)
      refreshCaptcha()
      throw res.data
    }
    if (res.data.code === 1000) {
      setToken(res.data.data)
      // TODO: save it to localStorage
      nextStep()
      return null
    }
    console.log(username, password, verifyCode, captcha.captchaId)
  }

  useEffect(() => {
    refreshCaptcha()
  }, [])

  return (
    <div {...styleClass(['layout'])}>
      <div>
        <Typography.Title level={3}>用户登录</Typography.Title>
      </div>
      <div>
        <span {...styleClass(['width350', 'margin10'])}>
          <Input
            placeholder="请输入用户名"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </span>
      </div>
      <div>
        <span {...styleClass(['width350', 'margin10'])}>
          <Input.Password
            placeholder="请输入密码"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </span>
      </div>
      <div
        {...styleClass(['width350', 'margin10'])}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <span {...styleClass(['width240'])}>
          <Input
            placeholder="请输入验证码"
            onChange={(e) => setVerifyCode(e.target.value)}
            value={verifyCode}
          />
          <span
            dangerouslySetInnerHTML={{ __html: captcha.data }}
            onClick={refreshCaptcha}
          />
        </span>
      </div>
      <Button
        onClick={login}
        type="primary"
        {...styleClass(['width350', 'margin10'])}
      >
        登录
      </Button>
    </div>
  )
}

const MarkPage = (props: { token: any; datasetID: number }) => {
  const styleClass = useStyles(styles)
  const { token, datasetID } = props
  const [compare, setCompare] = useState('=')
  const [leftSegment, setLeftSegment] = useState()
  const [rightSegment, setRightSegment] = useState()
  const [stage, setStage] = useState('to mark')
  const [awaitCount, setAwaitCount] = useState(0)
  const [sortKey, setSortKey] = useState('')
  const [muted, setMuted] = useState(false)
  const [modality, setModality] = useState<OssAssetFolder>('videos')
  const [prompt, setPrompt] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(0)
  const initialRequestForNextQuestion = isMocking
    ? mockMakeInitialRequestForNextQuestion()
    : async (props: { token; datasetID }) => {
        return (
          await axios.post(
            `${baseUrl}/app/video/mark/init`,
            { datasetID: props.datasetID },
            {
              headers: {
                Authorization: token.token,
              },
            }
          )
        ).data
      }
  const submitAndRequestForNextQuestion = isMocking
    ? mockMakeSubmitAndRequestForNextQuestion()
    : async (params: {
        token: any
        datasetID: any
        videoOSSID1: any
        videoOSSID2: any
        compare: any
      }) => {
        const { token, datasetID, videoOSSID1, videoOSSID2, compare } = params
        return (
          await axios.post(
            `${baseUrl}/app/video/mark/submit`,
            { datasetID, videoOSSID1, videoOSSID2, compare },
            {
              headers: {
                Authorization: token.token,
              },
            }
          )
        ).data
      }

  const handleSubmit = async () => {
    if (compare !== '<' && compare !== '>') {
      message.error('请选择比较项')
      return
    }
    console.log('handleSubmit', 'before Submit')
    const { code, data } = await submitAndRequestForNextQuestion({
      token,
      datasetID,
      videoOSSID1: leftSegment,
      videoOSSID2: rightSegment,
      compare,
    })
    console.log('handleSubmit', data)
    setStage(data.status)
    if (data.status === 'to mark') {
      setLeftSegment(data?.left)
      setRightSegment(data?.right)
      setCount(data?.count ?? 0)
      setCompare('=')
    } else if (data.status === 'should await') {
      setAwaitCount(data?.awaitCount ?? 0)
    }
  }

  const init = async () => {
    const { code, data } = await initialRequestForNextQuestion({
      token,
      datasetID,
    })
    console.log('init', data)
    setStage(data.status)
    if (data.status === 'to mark') {
      setLeftSegment(data?.left)
      setRightSegment(data?.right)
      setSortKey(data?.sortKey)
      setMuted(data?.muted)
      setModality(data?.modality)
      setPrompt(data?.prompt ?? '')
      setCount(data?.count ?? 0)
    } else if (data.status === 'should await') {
      setAwaitCount(data?.awaitCount ?? 0)
    }
  }

  useEffect(() => {
    init()
      .then(() => new Promise((resolve) => setTimeout(resolve, 500)))
      .then((data) => setIsLoading(false))
      .catch((err) => console.error(err))
  }, [])

  return stage === 'done' ? (
    <div>您已对所有题目打标完成，感谢您的参与！</div>
  ) : stage === 'should await' ? (
    <div>等待其他用户打标，还需等待{awaitCount}项</div>
  ) : isLoading ? (
    <div>加载中</div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {prompt !== '' ? (
        <div>
          <ReactMarkdown>{prompt}</ReactMarkdown>
        </div>
      ) : (
        <div>
          请从下面两个视频中选择您认为
          <span style={{ color: 'red' }}>{sortKey}</span>更高的一项
        </div>
      )}
      {modality === 'audios' ? (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            {...styleClass(
              compare === '>'
                ? ['border-selected', 'width400']
                : ['border-unselected', 'width400']
            )}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactAudioPlayer
              muted={muted}
              controls
              src={getOSSURL(modality, leftSegment)}
            />
          </div>
          <div
            {...styleClass(
              compare === '<'
                ? ['border-selected', 'width400']
                : ['border-unselected', 'width400']
            )}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactAudioPlayer
              muted={muted}
              controls
              src={getOSSURL(modality, rightSegment)}
            />
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div
            {...styleClass(
              compare === '>' ? ['border-selected'] : ['border-unselected']
            )}
          >
            <ReactPlayer
              width={400}
              muted={muted}
              controls
              url={getOSSURL(modality, leftSegment)}
            />
          </div>
          <div
            {...styleClass(
              compare === '<' ? ['border-selected'] : ['border-unselected']
            )}
          >
            <ReactPlayer
              width={400}
              muted={muted}
              controls
              url={getOSSURL(modality, rightSegment)}
            />
          </div>
        </div>
      )}
      <div>
        <Radio.Group
          value={compare}
          onChange={(e) => setCompare(e.target.value)}
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Radio
            value=">"
            {...styleClass(['width400'])}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div>左侧({leftSegment})</div>
          </Radio>
          <Radio
            value="<"
            {...styleClass(['width400'])}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div>右侧({rightSegment})</div>
          </Radio>
        </Radio.Group>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        // {...styleClass(['width350'])}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          // {...styleClass(['width350'])}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            {...styleClass(['width350', 'margin10'])}
          >
            确认并移至下一题
          </Button>
        </div>
        <div
          {...styleClass(['flexBox1', 'margin10'])}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography.Text>当前打标次数: {count}</Typography.Text>
        </div>
      </div>
    </div>
  )
}

const MarkWithBenchmarksPage = (props: { token: any; datasetID: number }) => {
  const { token, datasetID } = props
  const [compare, setCompare] = useState('=')
  const [leftSegment, setLeftSegment] = useState()
  const [stage, setStage] = useState('to mark')
  const [sortKey, setSortKey] = useState('')
  const [muted, setMuted] = useState(false)
  const [modality, setModality] = useState<OssAssetFolder>('videos')
  const [prompt, setPrompt] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [round, setRound] = useState(0)
  const [rank, setRank] = useState(0)
  const [benchmarks, setBenchmarks] = useState([])
  const initialRequestForNextQuestion = isMocking
    ? mockMakeInitialRequestForNextQuestion()
    : async (props: { token; datasetID }) => {
        return (
          await axios.post(
            `${baseUrl}/app/video/mark-with-benchmarks/init`,
            { datasetID: props.datasetID },
            {
              headers: {
                Authorization: token.token,
              },
            }
          )
        ).data
      }
  const submitAndRequestForNextQuestion = isMocking
    ? mockMakeSubmitAndRequestForNextQuestion()
    : async (params: {
        token: any
        datasetID: any
        segment: any
        rank: any
      }) => {
        const { token, datasetID, segment, rank } = params
        return (
          await axios.post(
            `${baseUrl}/app/video/mark-with-benchmarks/submit`,
            { datasetID, segment, rank },
            {
              headers: {
                Authorization: token.token,
              },
            }
          )
        ).data
      }

  const handleSubmit = async () => {
    if (compare !== '<' && compare !== '>') {
      message.error('请选择比较项')
      return
    }
    if (rank % 2 === 1) {
      const delta = (benchmarks.length + 1) / Math.pow(2, round + 1) / 2
      setRank(rank + (compare === '>' ? 1 : -1) * delta)
      console.log(rank, delta)
      setRound(round + 1)
      setCompare('=')
    } else {
      const { code, data } = await submitAndRequestForNextQuestion({
        token,
        datasetID,
        segment: leftSegment,
        rank: rank + (compare === '>' ? 1 : 0),
      })
      setStage(data.status)
      if (data.status === 'to mark') {
        setLeftSegment(data?.left)
        setRank(Math.floor(data?.benchmarks?.length / 2))
        setRound(0)
        setCount(data?.count ?? 0)
        setCompare('=')
      } else if (data.status === 'done') {
      } else {
        message.error('提交出错，请联系管理员')
      }
    }
  }

  const init = async () => {
    const { code, data } = await initialRequestForNextQuestion({
      token,
      datasetID,
    })
    // console.log('init', data)
    setStage(data.status)
    if (data.status === 'to mark') {
      setLeftSegment(data?.left)
      setSortKey(data?.sortKey)
      setMuted(data?.muted)
      setModality(data?.modality)
      setPrompt(data?.prompt ?? '')
      setRound(0)
      setCount(data?.count ?? 0)
      setRank(Math.floor(data?.benchmarks?.length / 2))
      setBenchmarks(data?.benchmarks)
    }
  }

  useEffect(() => {
    init()
      .then(() => new Promise((resolve) => setTimeout(resolve, 500)))
      .then((data) => setIsLoading(false))
      .catch((err) => console.error(err))
  }, [])

  return stage === 'done' ? (
    <div>您已对所有题目打标完成，感谢您的参与！</div>
  ) : isLoading ? (
    <div>加载中</div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {prompt !== '' ? (
        <ReactMarkdown>{prompt}</ReactMarkdown>
      ) : (
        <div>
          请从下面两个视频中选择您认为
          <span style={{ color: 'red' }}>{sortKey}</span>更高的一项
        </div>
      )}
      {modality === 'audios' ? (
        <div style={{ display: 'flex' }}>
          <ReactAudioPlayer
            muted={muted}
            controls
            src={getOSSURL(modality, leftSegment)}
          />
          <ReactAudioPlayer
            muted={muted}
            controls
            src={getOSSURL(modality, benchmarks[rank])}
          />
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <ReactPlayer
            width={400}
            muted={muted}
            controls
            url={getOSSURL(modality, leftSegment)}
          />
          <ReactPlayer
            width={400}
            muted={muted}
            controls
            url={getOSSURL(modality, benchmarks[rank])}
          />
        </div>
      )}
      <div>
        <Radio.Group
          value={compare}
          onChange={(e) => setCompare(e.target.value)}
        >
          <Radio value=">">左侧</Radio>
          <Radio value="<">右侧</Radio>
        </Radio.Group>
      </div>
      <div>
        已完成视频个数：{count}；当前视频和标杆视频的比较次数：{round}
      </div>
      <Button onClick={handleSubmit}>确认并移至下一题</Button>
    </div>
  )
}

const MarkWithBenchmarksV2Page = (props: { token: any; datasetID: number }) => {
  const styleClass = useStyles(styles)
  const { token, datasetID } = props
  const [leftSegment, setLeftSegment] = useState()
  const [stage, setStage] = useState('to mark')
  const [sortKey, setSortKey] = useState('')
  const [muted, setMuted] = useState(false)
  const [modality, setModality] = useState<OssAssetFolder>('videos')
  const [prompt, setPrompt] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [benchmarks, setBenchmarks] = useState([])
  const [rangeMin, setRangeMin] = useState(0)
  const [rangeMax, setRangeMax] = useState(0)
  const [scale, setScale] = useState(0)
  const [score, setScore] = useState(0)
  const [refLow, setRefLow] = useState(undefined)
  const [refHigh, setRefHigh] = useState(undefined)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalVideoSegment, setModalVideoSegment] = useState('')

  const getMainVideoWidth = () => {
    // console.log('[getMainVideoWidth] ', 600, window.innerWidth)
    return Math.max(600, window.innerWidth * 0.6)
  }

  const [mainVideoWidth, setMainVideoWidth] = useState(getMainVideoWidth())
  const getBaseURL = () => `${baseUrl}/app/video/mark-with-benchmarks-v2`

  const initialRequestForNextQuestion = isMocking
    ? mockMakeInitialRequestForNextQuestion()
    : async (props: { token; datasetID }) => {
        return (
          await axios.post(
            `${getBaseURL()}/init`,
            { datasetID: props.datasetID },
            {
              headers: {
                Authorization: token.token,
              },
            }
          )
        ).data
      }
  const submitAndRequestForNextQuestion = isMocking
    ? mockMakeSubmitAndRequestForNextQuestion()
    : async (params: {
        token: any
        datasetID: any
        segment: any
        score: any
      }) => {
        const { token, datasetID, segment, score } = params
        return (
          await axios.post(
            `${getBaseURL()}/submit`,
            { datasetID, segment, score },
            {
              headers: {
                Authorization: token.token,
              },
            }
          )
        ).data
      }

  const handleSubmit = async () => {
    const { code, data } = await submitAndRequestForNextQuestion({
      token,
      datasetID,
      segment: leftSegment,
      score,
    })
    setStage(data.status)
    if (data.status === 'to mark') {
      setLeftSegment(data?.left)
      setScore(data.rangeMin)
      setCount(data?.count)
    } else if (data.status === 'done') {
    } else {
      message.error('提交出错，请联系管理员')
    }
  }

  const init = async () => {
    const { code, data } = await initialRequestForNextQuestion({
      token,
      datasetID,
    })
    // console.log('init', data)
    setStage(data.status)
    if (data.status === 'to mark') {
      setLeftSegment(data?.left)
      setSortKey(data?.sortKey)
      setMuted(data?.muted)
      setModality(data?.modality)
      setPrompt(data?.prompt ?? '')
      setBenchmarks(data?.benchmarks)
      setRangeMin(data?.rangeMin)
      setRangeMax(data?.rangeMax)
      setScale(data?.scale)
      setScore(data?.rangeMin)
      setCount(data?.count)
    }
  }

  // TODO: add throttling
  const handleQuery = async (tmpScore: number) => {
    const { code, data } = (
      await axios.post(
        `${getBaseURL()}/query`,
        { datasetID, score: tmpScore },
        {
          headers: {
            Authorization: token.token,
          },
        }
      )
    ).data
    setRefLow(data?.refLow)
    setRefHigh(data?.refHigh)
    // console.log('query', data)
  }

  useEffect(() => {
    init()
      .then(() => new Promise((resolve) => setTimeout(resolve, 500)))
      .then((data) => setIsLoading(false))
      .catch((err) => console.error(err))
  }, [])

  const handleResize = () => {
    setMainVideoWidth(getMainVideoWidth())
    // console.log('handleResize', window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const [modalVideo, setModalVideo] = useState(null)

  useEffect(() => {
    // if (modalOpen) {
    setModalVideo(
      <MusicOrVideo
        modality={modality}
        width={450}
        height={450}
        muted={muted}
        controls
        url={
          getOSSURL(modality, modalVideoSegment) + `?q=${new Date().getTime()}`
        }
      />
    )
    // } else {
    //   // console.log('hello')
    //   setModalVideo(null)
    // }
  }, [modalOpen, modality, modalVideoSegment])

  const benchmark2Marks = (benchmarks) => {
    const res = {}
    for (const benchmark of benchmarks) {
      res[benchmark.score] = {
        style: {
          cursor: 'pointer !important',
          // transform: "translateY(-140px)",
        },
        label: (
          <div key={benchmark.segment} style={{ cursor: 'pointer !important' }}>
            <div>
              <Typography.Text>{benchmark.score}</Typography.Text>
            </div>
            <div
              style={{ transform: 'translateY(-30px)' }}
              onClick={() => {
                setModalOpen(true)
                setModalVideoSegment(benchmark.segment)
              }}
            >
              <MusicOrVideo
                useIcon={modality.startsWith('audio')}
                modality={modality}
                width={150}
                height={150}
                muted={muted}
                controls={false}
                url={getOSSURL(modality, benchmark.segment)}
              />
            </div>
          </div>
        ),
      }
    }
    return res
  }

  return stage === 'done' ? (
    <div>您已对所有题目打标完成，感谢您的参与！</div>
  ) : isLoading ? (
    <div>加载中</div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Modal
        title="视频预览"
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {modalOpen ? (
          <MusicOrVideo
            modality={modality}
            width={450}
            height={450}
            muted={muted}
            controls
            url={
              getOSSURL(modality, modalVideoSegment) +
              `?q=${new Date().getTime()}`
            }
          />
        ) : null}
        {/* <ReactPlayer
          width={450}
          height={450}
          muted={muted}
          controls
          url={
            getOSSURL(modality, modalVideoSegment) +
            `?q=${new Date().getTime()}`
          }
        /> */}
      </Modal>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {modality === 'audios' ? (
            <div {...styleClass(['width60p'])}>
              <ReactAudioPlayer
                muted={muted}
                controls
                src={getOSSURL(modality, leftSegment)}
              />
            </div>
          ) : (
            <div {...styleClass(['width60p'])}>
              <ReactPlayer
                width={mainVideoWidth}
                muted={muted}
                controls
                url={getOSSURL(modality, leftSegment)}
              />
            </div>
          )}
        </div>
        <div {...styleClass(['card'])}>
          {prompt !== '' ? (
            <div>
              <ReactMarkdown>{prompt}</ReactMarkdown>
            </div>
          ) : (
            <div>
              请从下面两个视频中选择您认为
              <span style={{ color: 'red' }}>{sortKey}</span>更高的一项
            </div>
          )}
        </div>
      </div>
      <div
        {...styleClass(['card'])}
        style={{
          height: '400px',
          marginTop: '30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Slider
          keyboard
          min={rangeMin}
          max={rangeMax}
          step={scale}
          value={score}
          tooltip={{
            placement: 'top',
            open: !modalOpen,
            formatter: (value) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '330px',
                  }}
                >
                  {!refLow ? (
                    <div style={{ width: '150px', height: '150px' }} />
                  ) : (
                    <div
                      onWheel={() => {
                        setModalOpen(true)
                        setModalVideoSegment(refLow.segment)
                      }}
                    >
                      <MusicOrVideo
                        modality={modality}
                        width={150}
                        height={150}
                        muted={muted}
                        controls={true}
                        url={getOSSURL(modality, refLow.segment)}
                      />
                    </div>
                  )}
                  <div>{value}</div>
                  {!refHigh ? null : (
                    <div
                      onWheel={() => {
                        setModalOpen(true)
                        setModalVideoSegment(refHigh.segment)
                      }}
                    >
                      <MusicOrVideo
                        modality={modality}
                        width={150}
                        height={150}
                        muted={muted}
                        controls={true}
                        url={getOSSURL(modality, refHigh.segment)}
                      />
                    </div>
                  )}
                </div>
                <div>*使用鼠标滚轮预览视频</div>
              </div>
            ),
          }}
          // marks={benchmark2Marks(benchmarks) as any}
          onChange={(value: number) => {
            handleQuery(value)
            setScore(value)
          }}
        />
        <Slider
          className="mark-slider"
          min={rangeMin}
          max={rangeMax}
          step={scale}
          marks={benchmark2Marks(benchmarks) as any}
          tooltip={{
            open: false,
          }}
          disabled
          // style={{
          //   cursor: 'pointer !important'
          // }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        // {...styleClass(['width350'])}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          // {...styleClass(['width350'])}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            {...styleClass(['width350', 'margin10'])}
          >
            确认并移至下一题
          </Button>
        </div>
        <div
          {...styleClass(['flexBox1', 'margin10'])}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography.Text>当前打标次数: {count}</Typography.Text>
        </div>
      </div>
    </div>
  )
}

const ChoosePage = (props: {
  datasetID: number
  setDatasetID: Dispatch<SetStateAction<number>>
  datasetWithBenchmarksID: number
  setDatasetWithBenchmarksID: Dispatch<SetStateAction<number>>
  setDatasetType: Dispatch<SetStateAction<string>>
  nextStep: () => void
}) => {
  const {
    datasetID,
    setDatasetID,
    datasetWithBenchmarksID,
    setDatasetWithBenchmarksID,
    setDatasetType,
    nextStep,
  } = props
  const styleClass = useStyles(styles)
  const [radioValue, setRadioValue] = useState('打标方式一')
  const [datasets, setDatasets] = useState([])
  const [datasetsWithBenchmarks, setDatasetsWithBenchmarks] = useState([])
  const fetchDatasets = async () => {
    setDatasets(
      isMocking
        ? mockDatasets
        : (await axios.post(`${baseUrl}/app/video/mark-dataset/list`)).data.data
    )
    setDatasetsWithBenchmarks(
      isMocking
        ? mockDatasets
        : (
            await axios.post(
              `${baseUrl}/app/video/mark-dataset-with-benchmarks-v2/list`
            )
          ).data.data
    )
  }

  useEffect(() => {
    fetchDatasets()
  }, [])

  return (
    <div {...styleClass(['layout'])}>
      <div style={{ alignItems: 'flex-start' }}>
        <div {...styleClass(['margin10'])}>
          <Typography.Text>请选择打标方式</Typography.Text>
        </div>
        <Radio.Group
          value={radioValue}
          onChange={(e) => setRadioValue(e.target.value)}
          {...styleClass(['margin10', 'width350'])}
        >
          <Radio value={'打标方式一'}>打标方式一</Radio>
          <Radio value={'打标方式二'}>打标方式二</Radio>
        </Radio.Group>
        <div {...styleClass(['margin10'])}>
          <Typography.Text>请选择数据集</Typography.Text>
        </div>
        {radioValue !== '打标方式一' ? null : (
          <div>
            <Select
              value={
                find(datasets, (dataset) => dataset.id === datasetID)?.name
              }
              options={datasets.map((dataset) => ({
                value: dataset.id,
                label: dataset.name,
              }))}
              onChange={(id) => {
                setDatasetID(id)
              }}
              {...styleClass(['margin10', 'width350'])}
            />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setDatasetType('normal')
                  nextStep()
                }}
                {...styleClass(['margin10', 'width350'])}
              >
                下一步
              </Button>
            </div>
          </div>
        )}
        {radioValue !== '打标方式二' ? null : (
          <div>
            <Select
              value={
                find(
                  datasetsWithBenchmarks,
                  (dataset) => dataset.id === datasetWithBenchmarksID
                )?.name
              }
              options={datasetsWithBenchmarks.map((dataset) => ({
                value: dataset.id,
                label: dataset.name,
              }))}
              onChange={(id) => {
                setDatasetWithBenchmarksID(id)
              }}
              {...styleClass(['margin10', 'width350'])}
            />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setDatasetType('with benchmarks')
                  nextStep()
                }}
                {...styleClass(['margin10', 'width350'])}
              >
                下一步
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const Step2Page = (props: { step; setStep }) => {
  const { step, setStep } = props
  const [token, setToken] = useState({ token: '' })
  const [datasetID, setDatasetID] = useState(mockSmallSadDataset.id)
  const [datasetWithBenchmarksID, setDatasetWithBenchmarksID] = useState(
    mockSmallSadDataset.id
  )
  const [datasetType, setDatasetType] = useState('normal')
  if (step === 0) {
    return (
      <AuthPage
        // setAuthed={setAuthed}
        setToken={setToken}
        nextStep={() => setStep((step) => step + 1)}
      />
    )
  }
  if (step === 1) {
    return (
      <ChoosePage
        datasetID={datasetID}
        setDatasetID={setDatasetID}
        datasetWithBenchmarksID={datasetWithBenchmarksID}
        setDatasetWithBenchmarksID={setDatasetWithBenchmarksID}
        setDatasetType={setDatasetType}
        nextStep={() => setStep((step) => step + 1)}
      />
    )
  }
  if (step === 2) {
    if (datasetType === 'normal') {
      return <MarkPage token={token} datasetID={datasetID} />
    } else {
      return (
        <MarkWithBenchmarksV2Page
          token={token}
          datasetID={datasetWithBenchmarksID}
        />
      )
    }
  }
}

const Page = () => {
  const [step, setStep] = useState(0)
  return (
    <div>
      <Steps
        current={step}
        items={[
          { title: '登录', icon: <UserOutlined rev={''} /> },
          { title: '选择数据集', icon: <DatabaseOutlined rev={''} /> },
          { title: '打标', icon: <BookOutlined rev={''} /> },
        ]}
      />
      <Step2Page step={step} setStep={setStep} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={step <= 0}
          onClick={() => setStep((step) => Math.floor(step - 1))}
        >
          上一步
        </Button>
      </div>
    </div>
  )
}

export default Page
