import { RouteObject, useNavigate } from 'react-router-dom'
import { FC, lazy, ReactNode, Suspense } from 'react'

import Home from '@/pages/home/Home'
import HomePage from '@/pages/homepage/HomePage'
import DataBrowser from '@/pages/data/DataBrowser'
import DataDetail from '@/pages/data/DataDetail'
import DataOverview from '@/pages/data/DataOverview'
import Mark from '@/pages/mark/Mark'
// const Graph = lazy(() => import('@/pages/graph/Graph'))

const lazyLoad = (children: ReactNode): ReactNode => {
  return <Suspense fallback={<div>loading</div>}>{children}</Suspense>
}

export const router: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
    children: [
      { path: '/data_browser', element: lazyLoad(<DataBrowser />) },
      { path: '/data_detail/:ID', element: lazyLoad(<DataDetail />) },
      { path: '/data_overview', element: lazyLoad(<DataOverview />) },
      { path: '/mark', element: lazyLoad(<Mark />) },
    ],
  },

  /* {
    path: '',
    element: <HomePage />,
  }, */
  /* {
    path: '/login',
    element: <Login />,
  }, */
]
