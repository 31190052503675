import { FC, useEffect, useRef, useState } from 'react'
import styles from './DataOverview.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useNavigate } from 'react-router-dom'
import { Image, Select, theme, Tooltip } from 'antd'
import { Chart } from '@antv/g2'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'

const DataOverview: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const navigation = useNavigate()

  const {
    token: { colorBgContainer },
  } = theme.useToken()
  return (
    <>
      <div {...styleClass(['layout'])}>
        <div {...styleClass(['header'])}>
          <div {...styleClass(['header-title'])}>资源概况</div>
        </div>

        <div {...styleClass(['content'])}>
          <div
            {...styleClass(['card', 'card-left'], {
              background: colorBgContainer,
            })}
          >
            <div {...styleClass(['title'])}>数据统计</div>
            <div {...styleClass(['statics-grid'])}>
              <Statics title={'视听资产总数'} value={'10,950'} />
              <Statics title={'音视频总时间跨度'} value={'972.5h'} />
              <Statics title={'涵盖感知特征条目'} value={'2x7 + 2'} />
            </div>
            <div {...styleClass(['divider-title'])}>
              <div {...styleClass(['divider-title-text'])}>
                {'音视频片段总览'}
              </div>
              <div {...styleClass(['divider-title-line'])}></div>
            </div>
            <div {...styleClass(['legend1'])}>
              <div {...styleClass(['legend1-item'])}>
                <img
                  src={
                    'https://s3.bmp.ovh/imgs/2024/11/28/869feae40a81b8f7.png'
                  }
                  width={8}
                />
                <div {...styleClass(['legend1-item-text'])}>
                  {'配对音视频: 6432'}
                </div>
              </div>
              <div {...styleClass(['legend1-item'])}>
                <img
                  src={
                    'https://s3.bmp.ovh/imgs/2024/11/28/61b897031969ab56.png'
                  }
                  width={8}
                />
                <div {...styleClass(['legend1-item-text'])}>
                  {'独立音频: 4096'}
                </div>
              </div>
            </div>

            <RadialBarChart />

            <WorldCloud />
          </div>

          <div
            {...styleClass(['card', 'card-right'], {
              background: colorBgContainer,
            })}
          >
            <div {...styleClass(['title'])}>{'视听感知表现'}</div>
            <div {...styleClass(['content'], { gap: 32, height: '100%' })}>
              <div {...styleClass(['right-card-left'])}>
                <SortBarChart />
              </div>
              <div {...styleClass(['right-card-right'])}>
                <Heatmap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DataOverview

interface IStatics {
  title?: string
  value?: string
}
const Statics: FC<IStatics> = ({ title, value }) => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  return (
    <div {...styleClass(['statics-layout'])}>
      <div {...styleClass(['statics-title'])}>{title}</div>
      <div {...styleClass(['statics-value'])}>{value}</div>
    </div>
  )
}

const RadialBarChart: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const data = [
    { term: '乡村音乐', count: 9 },
    { term: '流行音乐', count: 8 },
    { term: 'Toy Story', count: 8 },
    { term: 'trashkannon', count: 7 },
    { term: 'the GROWLERS', count: 6 },
    { term: 'mudweiser', count: 6 },
    { term: 'ThunderCats', count: 4 },
    { term: 'The Taqwacores - Motion Picture', count: 4 },
    { term: 'The Shawshank Redemption', count: 2 },
    { term: 'The Olivia Experiment', count: 1 },
  ]

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const chart = new Chart({
      container: container,
      autoFit: true,
      padding: 0,
      height: 480,
    })
    chart
      .data(data)
      .coordinate({ type: 'radial', innerRadius: 0.45, endAngle: Math.PI })
    chart
      .interval()
      .encode('x', 'term')
      .encode('y', 'count')
      .encode('size', 5)
      .axis({
        y: false,
        x: {
          title: false,
          labelFill: '#fff',
          labelFontSize: 10,
          labelOpacity: 0.5,
        },
      })

    chart
      .point()
      .encode('x', 'term')
      .encode('y', 'count')
      .encode('shape', 'point')
      .encode('size', 4)
      .tooltip({
        title: (item) => item.term,
        items: [
          (item) => ({
            name: 'count',
            value: item.count,
          }),
        ],
      })

    chart
      .text()
      .style('text', 'Music/Video')
      .style('x', '50%')
      .style('y', '50%')
      .style('textAlign', 'center')
      .style('fontSize', 24)
      .style('fill', 'rgba(255,255,255,1)')

    chart.render()
  }, [])
  return (
    <div
      ref={containerRef}
      {...styleClass(['radial-bar-chart'], { marginTop: -40 })}
    ></div>
  )
}

const WorldCloud: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''
    const chart = new Chart({
      container: container,
      autoFit: true,
      padding: 0,
      height: 280,
    })

    chart
      .wordCloud()
      .data({
        type: 'fetch',
        value: 'https://assets.antv.antgroup.com/g2/philosophy-word.json',
      })
      .layout({
        spiral: 'rectangular',
        fontSize: [8, 28],
      })
      .encode('color', 'text')
      .legend(false)

    chart.render()
  }, [])
  return (
    <div
      ref={containerRef}
      {...styleClass(['radial-bar-chart'], { marginTop: -60 })}
    ></div>
  )
}

const SortBarChart: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const [selectFeature, setSelectFeature] = useState('叙事紧迫感')
  const [temp, setTemp] = useState([])

  useEffect(() => {
    const result = []
    for (let i = 0; i < 150; i++) {
      result.push({ id: `test-${i}`, value: Math.random() })
    }
    result.sort((a, b) => b.value - a.value)
    setTemp(result)
  }, [])

  return (
    <div
      {...styleClass([], {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      })}
    >
      <div {...styleClass(['right-card-left-header'])}>
        <img
          src={'https://s3.bmp.ovh/imgs/2024/12/04/142266262cf9002f.png'}
          width={22}
          height={18}
        ></img>
        <div {...styleClass(['right-card-left-header-divider'])}></div>
        <div {...styleClass(['right-card-left-header-text'])}>
          {'感知特征：'}
        </div>
        <Select
          {...styleClass(['right-card-left-header-select'])}
          value={selectFeature}
          onChange={setSelectFeature}
          options={[
            { value: '叙事紧迫感', label: '叙事紧迫感' },
            { value: '叙事高潮感', label: '叙事高潮感' },
            { value: '形象亲和感', label: '形象亲和感' },
            { value: '人物印象感', label: '人物印象感' },
            { value: '场景印象感', label: '场景印象感' },
            { value: '画面情感效价', label: '画面情感效价', disabled: true },
            {
              value: '画面情感唤醒度',
              label: '画面情感唤醒度',
              disabled: true,
            },
          ]}
        ></Select>
      </div>

      <div {...styleClass(['right-card-left-content'])}>
        <div {...styleClass(['right-card-left-content-ratio'])}>
          <div
            {...styleClass(['right-card-left-content-ratio-item'], {
              background: '#6872D4',
              height: '25%',
            })}
          ></div>
          <div {...styleClass(['right-card-left-content-ratio-text'])}>
            {'25'}
          </div>
          <div
            {...styleClass(['right-card-left-content-ratio-item'], {
              background: '#D89614',
              display: 'flex',
              flex: 1,
            })}
          ></div>
        </div>

        <div {...styleClass(['right-card-left-content-area'])}>
          {temp.map((item, index) => (
            <Tooltip key={item.id} placement={'right'} title={item.id}>
              <motion.div
                {...styleClass(['right-card-left-content-bar-layout'])}
                initial={{ background: 'rgba(255,255,255,0)' }}
                whileHover={{ background: 'rgba(255,255,255,0.2)' }}
                transition={{ ...motionEasing }}
              >
                <div
                  {...styleClass(['right-card-left-content-bar-item'], {
                    width: `${item.value * 100}%`,
                    opacity: index % 2 === 0 ? 1 : 0.5,
                  })}
                ></div>
              </motion.div>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  )
}

const Heatmap: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const containerRef = useRef()

  const data = [
    { userID: 1, feature: '特征1', score: 1, id: '123' },
    { userID: 1, feature: '特征2', score: 7 },
    { userID: 2, feature: '特征1', score: 4 },
    { userID: 3, feature: '特征3', score: 5 },
    { userID: 4, feature: '特征4', score: 5 },
    { userID: 5, feature: '特征5', score: 5 },
  ]

  useEffect(() => {
    const container = containerRef.current as HTMLElement
    container.innerHTML = ''

    const chart = new Chart({
      container: container,
      autoFit: true,
      height: 360,
      theme: 'classicDark',
      paddingBottom: 4,
      paddingLeft: 24,
    })

    const facetRect = chart.facetRect().data(data).encode('x', 'userID')

    facetRect
      .point()
      .attr('inset', 10)
      .encode('x', 'score')
      .encode('y', 'feature')
      .encode('color', 'score')
      .encode('shape', 'point')
      .scale('color', {
        palette: 'puBu',
        offset: (t) => 1 - t,
      })
      // .style('fill', 'rgba(255,255,255,0.2)')
      .style('lineWidth', 0)
      .attr('frame', false) // Hide the default frame.
      .viewStyle('plotStroke', '#fff') // Customize the plot area to mock a frame.
      .viewStyle('plotLineWidth', 2)
      .viewStyle('plotOpacity', 0.2)
      .axis({
        x: {
          labelFill: '#fff',
          labelFontSize: 10,
          labelOpacity: 0.5,
          gridStroke: '#fff',
        },
        y: {
          labelFill: '#fff',
          labelFontSize: 10,
          labelOpacity: 0.5,
          labelAutoRotate: true,
          gridStroke: '#fff',
        },
      })
      .legend('color', {
        ribbonSize: 0,
        handle: false,
        label: false,
        title: false,
      })
      .tooltip({ name: '特征', channel: 'y' })
      .tooltip({ name: '打标分数', channel: 'x' })
      .tooltip({ title: 'id' })

    chart.render()
  }, [])
  return <div ref={containerRef} {...styleClass(['heatmap'])}></div>
}
