import React, { Key, ReactNode } from 'react'
import { useStyles } from '@/hooks/styles'
import { Outlet, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@/components/icons'
import styles from './Home.module.scss'
import {
  AppstoreAddOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  ForkOutlined,
  HeatMapOutlined,
} from '@ant-design/icons'
import { Layout, Menu, MenuProps, theme } from 'antd'
import { MusicVideo } from '@/components/icons/MusicVideo'

const { Header, Content, Footer, Sider } = Layout
type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuProps['items'] = [
  getItem('视听资源', '1', <DatabaseOutlined rev={null} />, [
    getItem('数据资产库', '1-1', null),
    getItem('资源概况', '1-2', null),
  ]),
  getItem('特征挖掘', '2', <ForkOutlined rev={null} />),
  getItem('情感化音乐推荐', '3', <HeatMapOutlined rev={null} />),
  getItem('个性化音乐检索', '4', <AppstoreAddOutlined rev={null} />),
  getItem('智能影视配乐', '5', <AppstoreAddOutlined rev={null} />),
  { type: 'divider' },
  getItem('数据标注', '6', <ContainerOutlined rev={null} />),
]

const Home = () => {
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigation = useNavigate()

  const handleMenuItem = ({ key }) => {
    switch (key) {
      case '1-1':
        navigation('/data_browser')
        break
      case '1-2':
        navigation('/data_overview')
        break
      case '6':
        navigation('/mark')
        break
    }
  }

  return (
    <Layout {...styleClass([], { width: '100vw', minHeight: '100vh' })}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken)
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type)
        }}
      >
        <div {...styleClass(['logo'])}>
          <SvgIcon icon={MusicVideo} />
          <div {...styleClass(['logo-text'])}>Music-Video</div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={['1']}
          defaultSelectedKeys={['1-1']}
          items={items}
          onClick={(itemMeta) => handleMenuItem(itemMeta)}
        />
      </Sider>
      <Layout>
        {/* <Header style={{ padding: 0, background: colorBgContainer }} /> */}
        <Content style={{ margin: '16px 16px 0' }}>
          <div
            style={{
              // padding: 16,
              minWidth: 760,
              minHeight: 400,
              // background: colorBgContainer,
              borderRadius: 6,
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: '16px 50px' }}>
          inlab © copyright
        </Footer>
      </Layout>
    </Layout>
  )
}

export default Home
