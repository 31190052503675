import { FC, useEffect, useRef, useState } from 'react'
import styles from './DataDetail.module.scss'
import { useColorVar, useStyles } from '@/hooks/styles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  Skeleton,
  Button,
  Typography,
  Modal,
  Collapse,
  theme,
  Segmented,
  Tooltip,
} from 'antd'
import AudioPlayer from 'react-h5-audio-player'
import videojs from 'video.js'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import Minimap from 'wavesurfer.js/dist/plugins/minimap.esm.js'
import { CaretLeftOutlined } from '@ant-design/icons'
import { IVideoMeta } from '@/store/videoStore'
import axios from 'axios'
import { baseUrl } from '@/config'
import { motion } from 'framer-motion'
import { motionEasing } from '@/config'

const DataDetail: FC = () => {
  const c = useColorVar()
  const styleClass = useStyles(styles)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigation = useNavigate()
  const location = useLocation()
  const { ID } = useParams()

  const containerRef = useRef(null)
  const videoRef = useRef(null)

  const [ifLoading, setIfLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [videoMeta, setVideoMeta] = useState<IVideoMeta>()
  const [segmentedV, setSegmenteV] = useState<'音频' | '视频'>('视频')

  let videoPlayer
  let ws
  const initWS = () => {
    ws = WaveSurfer.create({
      container: containerRef.current,
      waveColor: '#C1C6FF',
      progressColor: c('primary'),
      // Pass the video element in the `media` param
      media: videoRef.current,
      plugins: [
        TimelinePlugin.create(),
        Minimap.create({
          height: 20,
          waveColor: '#ddd',
          progressColor: '#999',
          // the Minimap takes all the same options as the WaveSurfer itself
        }),
      ],
      renderFunction: (channels, ctx) => {
        const { width, height } = ctx.canvas
        const scale = channels[0].length / width
        const step = 4

        ctx.translate(0, height / 2)
        ctx.strokeStyle = ctx.fillStyle
        ctx.beginPath()

        for (let i = 0; i < width; i += step * 2) {
          const index = Math.floor(i * scale)
          const value = Math.abs(channels[0][index])
          let x = i
          let y = value * height

          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
          ctx.lineTo(x + step, 0)

          x = x + step
          y = -y
          ctx.moveTo(x, 0)
          ctx.lineTo(x, y)
          ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
          ctx.lineTo(x + step, 0)
        }

        ctx.stroke()
        ctx.closePath()
      },
    })
  }

  const getMovieName = (movieName: string) => {
    return movieName.replace(' - 电影', '')
  }
  const getRegion = (regions: string[]) => {
    let result = ''
    regions.map((item, _index) => (result += `${item} `))
    return result
  }

  const getVideoMeta = async () => {
    await axios
      .post(`${baseUrl}/app/video/video/getDetail`, { ID: ID })
      .then((res) => {
        console.log(res.data.data)
        setVideoMeta(res.data.data)
        // videoStore.setVideoList(res.data.data)
        // messageApi.success({ key: 'getData', content: '数据加载完毕' })
        // setIfLoading(false)
        return
      })
      .catch((error) => {
        console.log('error', error)
        // messageApi.error({ key: 'getData', content: '数据加载失败' })
        // setIfLoading(false)
        return
      })
  }

  useEffect(() => {
    getVideoMeta()
  }, [])

  useEffect(() => {
    if (videoMeta) {
      videoPlayer = videojs(videoRef.current)

      videoPlayer.on('loadedmetadata', () => {
        console.log('video loaded')
        initWS()
        ws.on('redrawcomplete', () => {
          setIfLoading(false)
        })
      })

      videoPlayer.src(videoMeta.videoUrl)
    }

    return () => {
      if (videoMeta) {
        videoPlayer.dispose()
        ws && ws.destroy()
      }
    }
  }, [videoMeta])

  return (
    <>
      <div {...styleClass(['layout'], { background: colorBgContainer })}>
        <div {...styleClass(['header'])}>
          <Button
            style={{ width: 32, height: 32, padding: 0 }}
            onClick={() => {
              if (location.state && location.state.jumped) {
                navigation(-1)
              } else {
                navigation('/data_browser')
              }
            }}
          >
            <CaretLeftOutlined rev={null} />
          </Button>
          <div {...styleClass(['header-text'])}>{'资源详情'}</div>
        </div>
        <div {...styleClass(['content'])}>
          <div {...styleClass(['media'])}>
            <Collapse
              items={[
                {
                  key: '1',
                  label: (
                    <div {...styleClass(['collapse-text'])}>
                      {videoMeta &&
                        `电影:${getMovieName(
                          videoMeta.movieName
                        )} --- 片段序号:${videoMeta.index}`}
                    </div>
                  ),
                  children: (
                    <div {...styleClass(['info-card-content'])}>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`类型: `}
                        </div>
                        <div {...styleClass(['info-card-content-tags'])}>
                          {videoMeta &&
                            videoMeta.tags.map((item, index) => (
                              <Typography.Text
                                key={`${item}-${index}`}
                                code
                                {...styleClass(['info-card-content-tags-text'])}
                              >
                                {item}
                              </Typography.Text>
                            ))}
                        </div>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`国家: `}
                        </div>
                        <div {...styleClass(['info-card-content-text'])}>
                          {videoMeta && getRegion(videoMeta.regions)}
                        </div>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`评分: `}
                        </div>
                        <Typography.Text
                          mark
                          {...styleClass(['info-card-content-text'])}
                        >
                          {videoMeta && `${videoMeta.score}`}
                        </Typography.Text>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`上映日期: `}
                        </div>
                        <Typography.Text
                          keyboard
                          {...styleClass(['info-card-content-text'])}
                        >
                          {videoMeta && `${videoMeta.release_date}`}
                        </Typography.Text>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`导演: `}
                        </div>
                        <div {...styleClass(['info-card-content-text'])}>
                          {videoMeta && `${videoMeta.directors}`}
                        </div>
                      </div>
                      <div {...styleClass(['info-card-content-block'])}>
                        <div {...styleClass(['info-card-content-title'])}>
                          {`描述: `}
                        </div>
                        <div {...styleClass(['info-card-content-text'])}>
                          {videoMeta && `${videoMeta.storyline}`}
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            ></Collapse>
            <div {...styleClass(['media-video'])}>
              <video
                ref={videoRef}
                {...styleClass([], {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
                  borderRadius: 6,
                  overflow: 'hidden',
                })}
                className="video-js"
                controls
                preload={'auto'}
                // poster={videoMeta.coverUrl}
              >
                <source
                  type="video/mp4"
                  src={videoMeta && videoMeta.videoUrl}
                ></source>
              </video>
            </div>

            <div {...styleClass(['media-audio-wrapper'])}>
              <div {...styleClass(['media-audio'])} ref={containerRef}></div>
              {ifLoading && (
                <Skeleton active {...styleClass(['media-audio-spin'])} />
              )}
            </div>
            <Button
              type={'default'}
              {...styleClass(['media-drawer-trigger'])}
              onClick={() => setOpen(true)}
            >
              原始音/视频
            </Button>
          </div>

          {/* 旧版本已弃用 */}
          {/* <div {...styleClass(['info'])}>
            <div {...styleClass(['info-card'])}>
              <div {...styleClass(['info-card-header'])}>
                <Typography.Text {...styleClass(['info-card-header-text'])}>
                  {videoMeta &&
                    `ID:${videoMeta.ID} --- IMDb_ID:${videoMeta.IMDb_ID}`}
                </Typography.Text>
              </div>
              <div {...styleClass(['info-card-divider'])}></div>
              <div {...styleClass(['info-card-content'])}>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`类型: `}
                  </div>
                  <div {...styleClass(['info-card-content-tags'])}>
                    {videoMeta &&
                      videoMeta.tags.map((item, index) => (
                        <Typography.Text
                          key={`${item}-${index}`}
                          code
                          {...styleClass(['info-card-content-tags-text'])}
                        >
                          {item}
                        </Typography.Text>
                      ))}
                  </div>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`国家: `}
                  </div>
                  <div {...styleClass(['info-card-content-text'])}>
                    {videoMeta && getRegion(videoMeta.regions)}
                  </div>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`评分: `}
                  </div>
                  <Typography.Text
                    mark
                    {...styleClass(['info-card-content-text'])}
                  >
                    {videoMeta && `${videoMeta.score}`}
                  </Typography.Text>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`上映日期: `}
                  </div>
                  <Typography.Text
                    keyboard
                    {...styleClass(['info-card-content-text'])}
                  >
                    {videoMeta && `${videoMeta.release_date}`}
                  </Typography.Text>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`导演: `}
                  </div>
                  <div {...styleClass(['info-card-content-text'])}>
                    {videoMeta && `${videoMeta.directors}`}
                  </div>
                </div>
                <div {...styleClass(['info-card-content-block'])}>
                  <div {...styleClass(['info-card-content-title'])}>
                    {`描述: `}
                  </div>
                  <div {...styleClass(['info-card-content-text'])}>
                    {videoMeta && `${videoMeta.storyline}`}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div {...styleClass(['data-sider'])}>
            <div {...styleClass(['data-sider-header'])}>
              <div {...styleClass(['data-sider-header-title'])}>
                {'特征标注'}
              </div>
              <Segmented
                options={['视频', '音频']}
                value={segmentedV}
                onChange={(value) => setSegmenteV(value as '视频' | '音频')}
              />
            </div>

            <div {...styleClass(['data-sider-divider'])}>
              <div {...styleClass(['data-sider-divider-line'])}></div>
              <div
                {...styleClass(['data-sider-divider-text'])}
              >{`感知特征【${segmentedV}】`}</div>
              <div {...styleClass(['data-sider-divider-line'])}></div>
            </div>

            <DistributionChart mode={segmentedV} />

            <div {...styleClass(['data-sider-divider'])}>
              <div {...styleClass(['data-sider-divider-line'])}></div>
              <div {...styleClass(['data-sider-divider-text'])}>{`情感VA`}</div>
              <div {...styleClass(['data-sider-divider-line'])}></div>
            </div>

            <BarChart
              arousal={-0.5}
              valence={0.7}
              mixedArousal={-0.45}
              mixedValence={0.3}
            />
          </div>
        </div>
      </div>

      <Modal
        width={780}
        title="原始音/视频"
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        open={open}
        footer={[
          <Button
            key={'confirm'}
            type={'primary'}
            onClick={() => setOpen(false)}
          >
            确认
          </Button>,
        ]}
      >
        <div {...styleClass(['drawer-layout'])}>
          <video
            // ref={videoRef}
            {...styleClass([], {
              width: 720,
              height: 405,
              objectFit: 'contain',
              boxShadow: '0 0 3px 0 rgba(255, 255, 255, 0.35)',
              borderRadius: 6,
              overflow: 'hidden',
            })}
            className="video-js"
            controls
            preload={'auto'}
            // poster={videoMeta.coverUrl}
          >
            <source
              type="video/mp4"
              src={videoMeta && videoMeta.originalVideoUrl}
            ></source>
          </video>
          <AudioPlayer
            style={{ width: 720 }}
            autoPlay={false} // 禁止自动播放
            hasDefaultKeyBindings={false} // 取消键盘绑定
            showFilledVolume={true}
            src={videoMeta && videoMeta.originalAudioUrl}
          />
        </div>
      </Modal>
    </>
  )
}

export default DataDetail

interface IDistributionChart {
  mode: '视频' | '音频'
}
const DistributionChart: FC<IDistributionChart> = ({ mode }) => {
  const styleClass = useStyles(styles)
  const [scores, setScores] = useState([])

  useEffect(() => {
    console.log(mode)
    // TODO: 临时mock数据
    function generateNestedArrayWithSum() {
      const nestedArray = []
      for (let i = 0; i < 6; i++) {
        const innerArray = Array(7).fill(0) // 初始化一个长度为 7 的元素为 0 的数组
        const sum = 5 // 目标和
        // 随机分配 5 的值到 7 个位置
        for (let j = 0; j < sum; j++) {
          // 随机选择一个位置进行增量
          const index = Math.floor(Math.random() * 7)
          innerArray[index]++
        }
        nestedArray.push(innerArray)
      }
      return nestedArray
    }

    setScores(generateNestedArrayWithSum)
  }, [mode])

  const videoFeatures = [
    { title: '叙事节奏', subtitle: '叙事紧迫感' },
    { title: '叙事内容', subtitle: '叙事高潮感' },
    { title: '形象亲缘', subtitle: '形象亲和感' },
    { title: '人物张力', subtitle: '人物印象感' },
    { title: '场景张力', subtitle: '场景印象感' },
    { title: '动作能量', subtitle: '动作能量感' },
  ]
  const audioFeatures = [
    { title: '音频', subtitle: '叙事紧迫感' },
    { title: '叙事内容', subtitle: '叙事高潮感' },
    { title: '形象亲缘', subtitle: '形象亲和感' },
    { title: '人物张力', subtitle: '人物印象感' },
    { title: '场景张力', subtitle: '场景印象感' },
    { title: '动作能量', subtitle: '动作能量感' },
  ]
  return (
    <div {...styleClass(['distribution-layout'])}>
      {scores.map((score, index) => (
        <DistributionChartItem
          key={
            mode === '视频'
              ? videoFeatures[index]['title']
              : audioFeatures[index]['title']
          }
          title={
            mode === '视频'
              ? videoFeatures[index]['title']
              : audioFeatures[index]['title']
          }
          subtitle={
            mode === '视频'
              ? videoFeatures[index]['subtitle']
              : audioFeatures[index]['subtitle']
          }
          score={score}
        />
      ))}
    </div>
  )
}

interface IDistributionChartItem {
  title?: string
  subtitle?: string
  score?: number[]
}
const DistributionChartItem: FC<IDistributionChartItem> = ({
  title,
  subtitle,
  score,
}) => {
  const styleClass = useStyles(styles)

  return (
    <div {...styleClass(['distribution-item'])}>
      <div {...styleClass(['distribution-item-left'])}>
        <div {...styleClass(['distribution-item-left-title'])}>{title}</div>
        <div {...styleClass(['distribution-item-left-subtitle'])}>
          {subtitle}
        </div>
      </div>

      <div {...styleClass(['distribution-item-right'])}>
        {score.map((freq, index) => {
          if (freq !== 0) {
            return (
              <Tooltip
                key={`${title}-${index}`}
                title={`${index + 1}分 - ${freq}人`}
              >
                <motion.div
                  {...styleClass(['distribution-item-right-block'], {
                    cursor: 'pointer',
                  })}
                  initial={{ background: 'rgba(255,255,255,0.05)' }}
                  whileHover={{ background: 'rgba(255,255,255,0.2)' }}
                  transition={{ ...motionEasing }}
                >
                  <div
                    {...styleClass(['distribution-item-right-block-inner'], {
                      width: `${(freq / 7) * 120}%`,
                      height: `${(freq / 7) * 120}%`,
                    })}
                  ></div>
                </motion.div>
              </Tooltip>
            )
          } else {
            return (
              <Tooltip
                key={`${title}-${index}`}
                title={`${index + 1}分 - ${freq}人`}
              >
                <motion.div
                  {...styleClass(['distribution-item-right-block'], {
                    cursor: 'pointer',
                  })}
                  initial={{ background: 'rgba(255,255,255,0)' }}
                  whileHover={{ background: 'rgba(255,255,255,0.05)' }}
                  transition={{ ...motionEasing }}
                >
                  <div
                    {...styleClass(['distribution-item-right-block-inner'], {
                      width: `${(freq / 7) * 120}%`,
                      height: `${(freq / 7) * 120}%`,
                    })}
                  ></div>
                </motion.div>
              </Tooltip>
            )
          }
        })}
      </div>
    </div>
  )
}

interface IBarChart {
  arousal?: number
  valence?: number
  mixedArousal?: number
  mixedValence?: number
}

const BarChart: FC<IBarChart> = ({
  arousal,
  valence,
  mixedArousal,
  mixedValence,
}) => {
  const styleClass = useStyles(styles)
  return (
    <div {...styleClass(['barchart'])}>
      <div {...styleClass(['barchart-item'])}>
        <div {...styleClass(['barchart-left'])}>
          <div {...styleClass(['barchart-left-title'])}>{'唤醒度A'}</div>
          <div {...styleClass(['barchart-left-subtitle'])}>
            {'情感的强烈程度'}
          </div>
        </div>
        <div {...styleClass(['barchart-right'])}>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#B0B7FE',
              top: 0,
              left: arousal >= 0 ? '50%' : `${50 - Math.abs(arousal) * 50}%`,
              width: `${Math.abs(arousal) * 50}%`,
            })}
          ></div>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#E7D4A5',
              top: '50%',
              left:
                mixedArousal >= 0
                  ? '50%'
                  : `${50 - Math.abs(mixedArousal) * 50}%`,
              width: `${Math.abs(mixedArousal) * 50}%`,
            })}
          ></div>
        </div>
      </div>

      <div {...styleClass(['barchart-item'])}>
        <div {...styleClass(['barchart-left'])}>
          <div {...styleClass(['barchart-left-title'])}>{'效价V'}</div>
          <div {...styleClass(['barchart-left-subtitle'])}>
            {'情感的价值取向'}
          </div>
        </div>

        <div {...styleClass(['barchart-right'])}>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#B0B7FE',
              top: 0,
              left: valence >= 0 ? '50%' : `${50 - Math.abs(valence) * 50}%`,
              width: `${Math.abs(valence) * 50}%`,
            })}
          ></div>
          <div
            {...styleClass(['barchart-right-bar'], {
              background: '#E7D4A5',
              top: '50%',
              left:
                mixedValence >= 0
                  ? '50%'
                  : `${50 - Math.abs(mixedValence) * 50}%`,
              width: `${Math.abs(mixedValence) * 50}%`,
            })}
          ></div>
        </div>
      </div>

      <div {...styleClass(['barchart-legend'])}>
        <div {...styleClass(['barchart-legend-item'])}>
          <img
            src={'https://s3.bmp.ovh/imgs/2024/11/30/88c7b204c0f64f76.png'}
            width={10}
          />
          <div {...styleClass(['barchart-legend-item-text'])}>
            {'音视频独立效果'}
          </div>
        </div>
        <div {...styleClass(['barchart-legend-item'])}>
          <img
            src={'https://s3.bmp.ovh/imgs/2024/11/30/39119c678ef596aa.png'}
            width={10}
          />
          <div {...styleClass(['barchart-legend-item-text'])}>
            {'音视频融合效果'}
          </div>
        </div>
      </div>
    </div>
  )
}
