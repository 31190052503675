import { FC, useEffect, useState } from 'react'
import styles from './DataBrowser.module.scss'
import { useStyles } from '@/hooks/styles'
import { VideoCard } from '@/components/VideoCard/VideoCard'
import { useNavigate } from 'react-router-dom'
import { ListResponsive } from '@/components/universal/ListResponsive/ListResponsive'
import {
  Pagination,
  Select,
  Input,
  message,
  Spin,
  theme,
  TreeSelect,
} from 'antd'
import { useVideoStore } from '@/hooks'
import axios from 'axios'
import { baseUrl, motionEasing } from '@/config'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react'
import { mobxProxyTrans } from '@/utils/basic'
import { movieTags } from '@/datas/movieTags'

const DataBrowser: FC = observer(() => {
  const styleClass = useStyles(styles)
  const navigation = useNavigate()
  const videoStore = useVideoStore()
  const [messageApi, contextHolder] = message.useMessage()
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const [totalItems, setTotalItems] = useState<number>(999)
  const [pageSize, setPageSize] = useState<number>(9) // 每页条数
  const [searchText, setSearchText] = useState<string>('')
  const [selectTags, setSelectTags] = useState<string[]>([])
  const [ifLoading, setIfLoading] = useState<boolean>(false)

  const handleColumnNumChange = (columnNum) => {
    setPageSize(3 * columnNum)
  }

  const getListData = async (pageNumber, pageSize, searchText, selectTags) => {
    setIfLoading(true)
    await axios
      .post(`${baseUrl}/app/video/video/get`, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(searchText !== '' && { searchText: searchText }),
        selectTags: selectTags,
      })
      .then((res) => {
        console.log(res.data.data)
        setTotalItems(res.data.data.pagination.total)
        videoStore.setVideoList(res.data.data.data)
        messageApi.success({ key: 'getData', content: '数据加载完毕' })
        setIfLoading(false)
        return
      })
      .catch((error) => {
        console.log('error', error)
        messageApi.error({ key: 'getData', content: '数据加载失败' })
        setIfLoading(false)
        return
      })
  }

  useEffect(() => {
    getListData(videoStore.pageNumber, pageSize, searchText, selectTags)
  }, [videoStore.pageNumber, pageSize, selectTags])

  return (
    <div {...styleClass(['layout'], { background: colorBgContainer })}>
      {contextHolder}
      <div {...styleClass(['title'])}>
        <div {...styleClass(['title-text'])}>{'数据资产库'}</div>
        <Input.Search
          variant={'filled'}
          placeholder="请搜索"
          loading={ifLoading}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={(value, event, info) =>
            getListData(
              videoStore.pageNumber,
              pageSize,
              info.source === 'clear' ? '' : value,
              selectTags
            )
          }
          allowClear
          style={{ width: '30%', maxWidth: 360, minWidth: 240 }}
        />
      </div>
      <div {...styleClass(['filter-layout'])}>
        <div {...styleClass(['filter-filter'])}>
          <div {...styleClass(['filter-filter-text'])}>{'数据集：'}</div>
          <TreeSelect
            placeholder={'影视配对数据集'}
            variant={'filled'}
            defaultValue={['1-1']}
            treeCheckable={true}
            style={{ width: 360 }}
            // onChange={handleChange}
            treeData={[
              {
                title: '配对数据集',
                value: '1',
                key: '1',
                children: [
                  {
                    title: '配对数据集1',
                    value: '1-1',
                    key: '1-1',
                  },
                  {
                    title: '配对数据集2',
                    value: '1-2',
                    key: '1-2',
                  },
                ],
              },
              {
                title: '民乐集',
                value: '2',
                key: '2',
              },
            ]}
          />
        </div>
        <div {...styleClass(['filter-filter'])}>
          <div {...styleClass(['filter-filter-text'])}>{'筛选：'}</div>
          <Select
            style={{ width: 280 }}
            placeholder={'电影类型'}
            showSearch={true}
            mode="multiple"
            variant={'filled'}
            maxTagCount={'responsive'}
            value={selectTags}
            onChange={(value) => setSelectTags(value)}
            optionFilterProp="label"
            options={movieTags}
          />
        </div>
        <div {...styleClass(['filter-sort'])}>
          <div {...styleClass(['filter-sort-text'])}>排序：</div>
          <Select
            variant={'filled'}
            defaultValue="电影名"
            style={{ width: 120 }}
            // onChange={handleChange}
            options={[
              { value: '电影名', label: '电影名', disabled: true },
              { value: '豆瓣评分', label: '豆瓣评分', disabled: true },
              { value: '情感特征', label: '情感特征', disabled: true },
            ]}
          />
        </div>
      </div>
      <ListResponsive
        {...styleClass(['list'], { gap: 22 })}
        onColumnNumChange={handleColumnNumChange}
      >
        {videoStore.videoList.map((data, index) => (
          <VideoCard
            key={data.ID}
            videoMeta={data}
            onClick={() =>
              navigation(`/data_detail/${data.ID}`, {
                state: { jumped: true },
              })
            }
          />
        ))}
        <AnimatePresence>
          {ifLoading && (
            <motion.div
              {...styleClass(['list-spin'])}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ...motionEasing }}
            >
              <Spin size={'large'} />
            </motion.div>
          )}
        </AnimatePresence>
      </ListResponsive>
      <div {...styleClass(['pagination'])}>
        <Pagination
          total={totalItems}
          current={videoStore.pageNumber}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            videoStore.setPageNumber(page)
          }}
          showSizeChanger={false}
          showQuickJumper
          showTotal={(total) => `共 ${total} 条片段`}
        />
      </div>
    </div>
  )
})

export default DataBrowser
